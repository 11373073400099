import { Box, useTheme } from "@mui/material";
import GeographyChart from "../../components/GeographyChart";
import Header from "../../components/Header";
import { tokens } from "../../theme";
import { MapContainer, TileLayer, useMap } from "react-leaflet";
import { Popup } from "react";


const Maps = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Box m="20px">
      <Header title="Dados espaciais" subtitle="Dados espaciais (em construção)" />

      <Box
        height="75vh"
        border={`1px solid ${colors.grey[100]}`}
        borderRadius="4px"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <MapContainer center={[51.505, -0.09]} zoom={13} scrollWheelZoom={false}>
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        
</MapContainer>
         <img
            alt="texto"
            height="700hr"
            src={`../../assets/mapasite.png`}
            style={{ cursor: "pointer" }}
          />
      </Box>
    </Box>
  );
};

export default Maps;