import { Box, Typography } from "@mui/material";

const Silvicultura = () => {
  return (
    <Box m="80px">
      
     
    
    <div style={{ textAlign:"justify", textIndent:"40px"}}>
    
    <Typography align="center" paddingTop="2px" variant="body1" style={{ fontSize:"18px", marginTop: "5px" }}>
      
    </Typography>      
    <Typography align="justify" paddingTop="2px" variant="body1" style={{ fontSize:"18px", marginTop: "5px" }}>
    No que se refere à silvicultura, entre os produtos de maior relevância para o estado, destaca-se o desempenho na produção de madeira em tora para papel e celulose, com valor de produção mais que duplicado em 2021 (142,7%), consolidando o estado como oitavo maior produtor entre as Unidades da Federação.
    </Typography>
    <Typography align="justify" paddingTop="2px" variant="body1" style={{ fontSize:"18px", marginTop: "5px" }}>
    Esse crescimento está diretamente relacionado com a produção em Açailândia, maior produtor do estado, cujo aumento foi de 90%. Como resultado, o município passou da 23ª posição para a 8ª no ranking nacional. os dados são do Instituto Maranhense de Estudos Socioeconômicos e Cartográficos (Imesc) através da publicação do Desempenho da Produção Florestal Maranhense em 2021

    </Typography>
  



   
    <Typography>



    </Typography>
  

    
    
    </div> 
    </Box>
  );
};

export default Silvicultura;
