import { Box, Typography } from "@mui/material";
import Header from "../../../components/Header";

const Avicultura = () => {
  return (
    <Box m="20px">
      <Header title="Avicultura" />
      <div style={{ textAlign:"justify", textIndent:"40px"}}>
    <Typography align="justify" paddingTop="2px" variant="body1" style={{ fontSize:"18px", marginTop: "5px" }}>
      Pagina em Construção.
    </Typography>
    <Typography align="justify" paddingTop="2px" variant="body1" style={{ fontSize:"18px", marginTop: "5px" }}>
    </Typography>
    <Typography align="justify" paddingTop="2px" variant="body1" style={{ fontSize:"18px", marginTop: "5px" }}>
    </Typography>

      </div>


    <Typography>



    </Typography>
  


    </Box>
  );
};

export default Avicultura;
