import React, { useEffect, useState } from "react";
import axios from "axios";

const Weather = () => {
  const [weatherData, setWeatherData] = useState(null);
  const [forecastData, setForecastData] = useState(null);

  useEffect(() => {
    const apiKey = "03b4333321ef221b05bb6135c9277227"; // Substitua pelo seu API Key do OpenWeatherMap

    const fetchCurrentWeatherData = async () => {
      try {
        const response = await axios.get(
          `https://api.openweathermap.org/data/2.5/weather?q=Sao%20Luis,br&lang=pt&units=metric&appid=${apiKey}`
        );

        if (response.status !== 200) {
          throw new Error("Falha ao buscar dados climáticos atuais");
        }

        const data = response.data;
        setWeatherData(data); // Atualiza o estado com os dados climáticos atuais
      } catch (error) {
        console.error("Erro ao buscar dados climáticos atuais:", error);
      }
    };

    // Função para buscar a previsão do tempo para os próximos 2 dias
    const fetchWeatherForecast = async () => {
      try {
        const response = await axios.get(
          `https://api.openweathermap.org/data/2.5/forecast?q=Sao%20Luis,br&lang=pt&units=metric&appid=${apiKey}`
        );

        if (response.status !== 200) {
          throw new Error("Falha ao buscar previsão do tempo");
        }

        const data = response.data;
        const tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);
        const dayAfterTomorrow = new Date();
        dayAfterTomorrow.setDate(dayAfterTomorrow.getDate() + 3);
        const filteredForecastData = data.list.filter((forecastItem) => {
          const forecastDate = new Date(forecastItem.dt * 1000);
          return (
            forecastDate >= tomorrow && 
            forecastDate <= dayAfterTomorrow &&
            forecastDate.getHours() === 12);
        });
        setForecastData(filteredForecastData); // Atualiza o estado com os dados da previsão do tempo
      } catch (error) {
        console.error("Erro ao buscar previsão do tempo:", error);
      }
    };

    // Chama ambas as funções de busca dos dados climáticos
    fetchCurrentWeatherData();
    fetchWeatherForecast();
  }, []);

  const formatDate = (date) => {
    return new Date(date).toLocaleDateString("pt-BR");
  };

  return (
    <div>
      <h3>Previsão do Tempo em São Luís - MA</h3>
      {weatherData ? (
        <div>
          <h5>Temperatura: {weatherData.main.temp} °C 
          <img src={`https://openweathermap.org/img/wn/${weatherData.weather[0].icon}.png`} alt={`${weatherData.weather[0].description}`} /></h5>
          {/* Renderize outras informações climáticas atuais conforme necessário */}
        </div>
      ) : ( 
        <br />
      )}

      {forecastData ? (
        <div>
          <p>Previsão do Tempo</p>
          {forecastData.map((forecastItem) => (
            <div key={forecastItem.dt}>
              <p>{formatDate(forecastItem.dt * 1000)}</p>
              <p>{forecastItem.weather[0].description}</p>
              <p>Temperatura Mínima: {forecastItem.main.temp_min} °C</p>
              <p>Temperatura Máxima: {forecastItem.main.temp_max} °C</p>
            </div>
          ))}
        </div>
      ) : (
        <br />
      )}
    </div>
  );
};

export default Weather;