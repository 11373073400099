import { Box, InputLabel, Select, MenuItem, FormControl } from "@mui/material";
import Header from "../../components/Header";
import LineChart from "../../components/LineChart";

const Line = () => {
  return (
    <Box m="20px">
      <Header title="Variação de preço" subtitle="Variação do preço da saca do café (R$/saca 60 Kg)" />
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Produto</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          label="Produto"
        >
          <MenuItem value={1} selected>Café</MenuItem>
          <MenuItem value={2}>Soja</MenuItem>
          <MenuItem value={3}>Milho</MenuItem>
        </Select>
    </FormControl>
      <Box height="75vh">
        <LineChart />
      </Box>
    </Box>
  );
};

export default Line;
