import { Box, Typography } from "@mui/material";
import Header from "../../components/Header";

const Pecuariaagua = () => {
  return (
    <Box m="80px">
      
      
    
    <div style={{ textAlign:"justify", textIndent:"40px"}}>
    <Typography align="justify" paddingTop="2px" variant="body1" style={{ marginTop:"10px", fontSize:"18px"}}>
      A Organização das Nações Unidas para Agricultura e Alimentação (FAO) cita que a aquicultura é a mais rápida das atividades agropecuárias em termos de resultados produtivos e capaz de responder com folga ao rápido crescimento populacional;
    </Typography>
      <div style={{ textAlign: "justify", textIndent: "40px" }}>
      <Typography align="justify" paddingTop="10px" variant="body1" style={{ fontSize: "18px" }}>
        Dentre as áreas de atuação da aquicultura existentes, os que mais se destacam são:
      </Typography>
      <ul>
        <li>
          <Typography align="justify" paddingTop="2px" variant="body1" style={{ fontSize: "18px" }}>
            piscicultura: criação de peixes em água doce, salobra ou marinha;
          </Typography>
        </li>
        <li>
          <Typography align="justify" paddingTop="2px" variant="body1" style={{ fontSize: "18px" }}>
            carcinicultura: criação de camarões e lagostas;
          </Typography>
        </li>
        <li>
          <Typography align="justify" paddingTop="2px" variant="body1" style={{ fontSize: "18px" }}>
            ostreicultura: criação de ostras;
          </Typography>
        </li>
        <li>
          <Typography align="justify" paddingTop="2px" variant="body1" style={{ fontSize: "18px" }}>
            algicultura: cultivo de algas;
          </Typography>
        </li>
        <li>
          <Typography align="justify" paddingTop="2px" variant="body1" style={{ fontSize: "18px" }}>
            ranicultura: criação de rãs;
          </Typography>
        </li>
        <li>
          <Typography align="justify" paddingTop="2px" variant="body1" style={{ fontSize: "18px" }}>
            quelonicultura: criação de tartarugas;
          </Typography>
        </li>
        <li>
          <Typography align="justify" paddingTop="2px" variant="body1" style={{ fontSize: "18px" }}>
            criação de jacarés;
          </Typography>
        </li>
      </ul>
    </div>



    

    <Typography align="justify" paddingTop="2px" variant="body1" style={{ fontSize:"18px", marginTop: "5px" }}>
      Sendo assim, a condução de uma política de fomento ao desenvolvimento sustentável da aquicultura com as diversas espécies da fauna e flora aquática pode sim, também ser conduzida pela SAGRIMA, porque se enquadrada dentre as atividades agrosilvipastoris destinadas ao uso econômico, a preservação e a conservação dos recursos naturais renováveis, ao que chamamos de PECUÁRIA DAS ÁGUAS ou AQUANEGÓCIO.
    </Typography>
    <Typography align="justify" paddingTop="2px" variant="body1" style={{ fontSize:"18px", marginTop: "5px" }}>
      Neste sentido e com a anuência do Governador, a SAGRIMA, mesmo após a criação da Secretaria de Pesca e Aquicultura, deu continuidade à execução do programa MAIS PESCADO ainda que este Programa tenha sido transferido para a SEPA.
    </Typography>
  </div>

  <div style={{ textAlign: "justify", textIndent: "40px" }}>
    <Typography align="justify" paddingTop="2px" variant="body1" style={{ marginTop: "10px", fontSize: "18px" }}>
      A Organização das Nações Unidas para Agricultura e Alimentação (FAO) cita que a aquicultura é a mais rápida das atividades agropecuárias em termos de resultados produtivos e capaz de responder com folga ao rápido crescimento populacional.
    </Typography>

    <Typography align="justify" paddingTop="2px" variant="body1" style={{ marginTop: "10px", fontSize: "18px" }}>
      Estas atividades são as seguintes:
    </Typography>

    <ul style={{ paddingLeft: "40px", listStyleType: "circle", fontSize: "18px" }}>
      <li>Incentivo ao empreendimento aquícola, por meio do fortalecimento de comunidades de produtores rurais com a oferta de:</li>
      <ul style={{ paddingLeft: "40px", listStyleType: "square" }}>
        <li>Serviços de Assistência Técnica aos aquicultores por meio do PROGRAMA INSTITUCIONAL DE RESIDÊNCIA PROFISSIONAL EM CIÊNCIAS AGRÁRIAS;</li>
        <li>Oferta de insumos e equipamentos, como: tanques-redes, alevinos, ração e kits de análise de qualidade das águas de cultivo, depuradora de ostras;</li>
      </ul>

      <li>Complexto Pesqueiro: Projeto inicial e obra executados pela AGEM. A SAGRIMA identificou a necessidade de alterações no projeto inicial para contemplar a área de inspeção sanitária de pescados que serão comercializados no local.</li>
      <li>Discussões técnicas para elaboração de projetos ligados a Aquicultura que subsidiarão o planejamento do PLANO MARANHÃO 2050 (com assento da Sagrima na Comissão Estadual) e PPA – 2024-2027;</li>
      <li>Planejamento para implementação da piscicultura aos Perímetros Irrigados de Salangô e Tabuleiro de São Bernardo;</li>
      <li>Participação nos Conselhos Estaduais de Recursos Hídricos (CONERH) e de Meio Ambiente (CONSEMA);</li>
      <li>Participação no Comitê Estadual de Sanidade de Animais Aquáticos (AGED);</li>
      <li>Planejamento, Condução e Avaliação das plenárias da Comissão Setorial de Aquicultura - CASEAQ;</li>
      <li>Articulação e planejamento para implementação de parceria público/privada com a Empresa ASA Carcinicultura (Bacabeira) para implantação experimental de sistema multitrófico de produção de camarão marinho consorciado com peixes, macroalgas e ostras, objetivando minimização de uso de ração, biorremediação, maior produtividade e adequação ambiental dos cultivos;</li>
      <li>Orientação de investidores na aquicultura para obtenção da outorga para o uso da água e licenciamento ambiental dos projetos;</li>
      <li>Orientação a potenciais investidores na escolha de locais para implantação de empreendimentos aquícolas, a exemplo das tratativas em curso com a Secretaria Municipal de Pesca e Aquicultura de Tutóia, ICMbio e agentes privados para implantação da cadeia de valor de algiostreicultura com foco na sustentabilidade e que oportunize sua expansão para todo o litoral do Estado;</li>
      <li>Elaboração de propostas de dispositivos legais para regulamentação da implantação de Polos Prioritários de Desenvolvimento da Carcinicultura no Estado;</li>
      <li>Planejamento e articulação com a FAMEM, Municípios e academia para a criação do CONSÓRCIO MARANHÃO AZUL pelos municípios do Estado defrontantes ao mar para implantação de projetos aquícolas, construindo um sistema econômico que utiliza uma abordagem sistêmica para manter o fluxo circular dos recursos, (economia circular) por meio da adição, retenção e regeneração de seu valor, contribuindo para o desenvolvimento sustentável.</li>
    </ul>
  </div>
    <Typography>



    </Typography>
  

    
        
    </Box>
  );
};

export default Pecuariaagua;
