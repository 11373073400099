import * as React from "react";
import { Box, Typography } from "@mui/material";
import { LineChart } from "@mui/x-charts/LineChart";
import { BarChart } from "@mui/x-charts/BarChart";

const Maranhaoprodutor = () => {

  return (
    <Box m="80px">
      <div style={{ textAlign: "justify", textIndent: "40px" }}>
        <Typography paddingTop="5px" variant="body1" style={{ fontSize: "20px" }}>
          Maranhão Produtor - Apresentar um formulário que Empreendedores poderão se cadastrar, criando assim uma dashboard com os produtores do Estado. Assim como apresentar em gráficos relatórios mensais relacionados a produção Estadual. 
        </Typography>
        <Typography align="justify" paddingTop="2px" variant="body1" style={{ fontSize: "18px", marginTop: "5px" }}>
          
        </Typography>
      </div>

  

    </Box>
  );
};

export default Maranhaoprodutor;