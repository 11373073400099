import { Box, Typography } from "@mui/material";
import Header from "../../../components/Header";

const Bovinocultura = () => {
  return (
    <Box m="20px">
      <Header title="Bovinocultura" />
      <div style={{ textAlign:"justify", textIndent:"40px"}}>
    <Typography align="justify" paddingTop="2px" variant="body1" style={{ fontSize:"18px", marginTop: "5px" }}>
      É uma atividade agropecuária voltada para a produção de carne e couro.
    </Typography>
    <Typography align="justify" paddingTop="2px" variant="body1" style={{ fontSize:"18px", marginTop: "5px" }}>
      A atividade está sendo cada vez mais estimulada a atender as exigências do mercado consumidor, na questão da qualidade da carne, seja pela própria indústria frigorífica, seja pela iniciativa governamental (GOMES et al, 2017).
    </Typography>
    <Typography align="justify" paddingTop="2px" variant="body1" style={{ fontSize:"18px", marginTop: "5px" }}>
      Dentre as raças de maior produção no Estado destacam-se: nelore e/ou mestiça de nelore, gir e guzerá, que são criadas em regime exclusivo de pastagens, acrescido de sal mineral, sendo as pastagens mais cultivadas o Brachiarão (Brachiaria brizantha), Mombaça (Panicum maximum), Massai (Panicum infestum), Brachiaria (Brachiaria ssp) e Andropogon (Andropogon gayanus).
    </Typography>
    <Typography align="justify" paddingTop="2px" variant="body1" style={{ fontSize:"18px", marginTop: "5px" }}>
      As regiões do Estado onde estão concentradas as maiores produções de bovinos de corte e raças são: Tocantina, Chapada das Mesas, Carajás e Gerais de Balsas (raças nelore, gir, tabapuã, guzerá e girolando); Mearim, Pindaré e Alto Turi (raças nelore, tabapuã, brahman e mestiços com outras raças); Serras (nelore e mestiços –  canchim, tabapuã, red angus).
    </Typography>
    <Typography align="justify" paddingTop="2px" variant="body1" style={{ fontSize:"18px", marginTop: "5px" }}>
      Nesse sentido, o produtor é o principal beneficiado ao tomar ciência das informações meteorológicas para elaboração do planejamento agrícola, pois ele arcará com custos elevadíssimos em caso de perdas na safra. Desse modo, a agrometeorologia pode contribuir para que haja um bom gerenciamento da área para a produção agrícola, realizando previsões meteorológicas por meio de modelos matemáticos e estatísticos, visando o maior índice de assertividade das previsões, para que o produtor possa se preparar para enfrentar os possíveis fenômenos climáticos extremos.
    </Typography>
    <Typography align="justify" paddingTop="10px" variant="body1" style={{ fontSize: "18px" }}>
      No ranking nacional da carne, o Maranhão se posiciona como:
    </Typography>
      <ul>
        <li>
          <Typography align="justify" paddingTop="2px" variant="body1" style={{ fontSize: "18px" }}>
            2° Maior Rebanho do Nordeste;
          </Typography>
        </li>
        <li>
          <Typography align="justify" paddingTop="2px" variant="body1" style={{ fontSize: "18px" }}>
            11° lugar de rebanho bovino - 9.428.128 milhões de cabeças;
          </Typography>
        </li>
        <li>
          <Typography align="justify" paddingTop="2px" variant="body1" style={{ fontSize: "18px" }}>
            18° lugar de exportação de couro do país;
          </Typography>
        </li>     
      </ul>
    <Typography align="justify" paddingTop="2px" variant="body1" style={{ fontSize:"18px", marginTop: "5px" }}>
      No primeiro trimestre de 2023 o quantitativo de bovinos abatidos foi de 159.204 animais e apresentando um aumento de 4,89% quando comparado com o trimestre anterior. 
    </Typography>
    </div>
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", textAlign: "center", marginRight: "20px" }}>
    <img
        alt="boi"
        height="200hr"
        src={`../../assets/nelore.webp`}
        style={{ cursor: "pointer"}}
      />
      <Typography>
        Nelore - Reprodução: Google Images
      </Typography>
      <img
        alt="boi"
        height="200hr"
        src={`../../assets/guzera.jpg`}
        style={{ cursor: "pointer"}}
      />
      <Typography>
        Guzera - Reprodução: Google Images
      </Typography>
      </div>


    <Typography>



    </Typography>

    <div style={{ textAlign:"justify", textIndent:"40px"}}>
    <Typography align="justify" paddingTop="2px" variant="body1" style={{ fontSize:"18px", marginTop: "5px" }}>
      A cadeia produtiva do leite está entre as principais atividades econômicas do Brasil, promovendo emprego e renda para os participantes da cadeia. O país encontra-se  como o terceiro maior produtor mundial de leite, segundo dados da Organização das Nações Unidas para Alimentação e Agricultura (FAO, 2019).
    </Typography>
    <Typography align="justify" paddingTop="2px" variant="body1" style={{ fontSize:"18px", marginTop: "5px" }}>
      A pecuária leiteira do Maranhão encontra-se, em sua grande maioria, como uma atividade de baixo nível de desenvolvimento tecnológico, com hegemonia de produtores familiares de leite, e a prevalência de sistemas de produção ineficientes. Esse cenário expõe a necessidade de buscar alternativas para melhorar a produção de leite no Estado, tendo em vista que o mercado no qual estão inseridos os produtores, está em crescimento  tornando-se mais competitivo e exigente no quesito qualidade da matéria-prima. (DANTAS et al., 2018, p.72).
    </Typography>
    <Typography align="justify" paddingTop="2px" variant="body1" style={{ fontSize:"18px", marginTop: "5px" }}>
      A produção de leite ocorre praticamente em todo o Estado, mas as principais áreas de produção encontram-se na região Tocantina e no Médio Mearim.
    </Typography>
    <Typography align="justify" paddingTop="2px" variant="body1" style={{ fontSize:"18px", marginTop: "5px" }}>
      O Estado do Maranhão aparece como o quarto produtor do Nordeste (sendo o segundo em área e segundo em rebanho) indicando um grande potencial produtivo ainda não explorado.
    </Typography>
    <Typography align="justify" paddingTop="2px" variant="body1" style={{ fontSize:"18px", marginTop: "5px" }}>
      A raça mais utilizada de gado leiteiro no Maranhão é a girolanda, que é responsável por 80% da produção de leite no Brasil.
    </Typography>
    <Typography align="justify" paddingTop="2px" variant="body1" style={{ fontSize:"18px", marginTop: "5px" }}>
      No segundo trimestre de 2023 o quantitativo de leite cru, resfriado ou não, que sofreu processo de industrialização foi de 13.672 (mil litros) e apresentando um aumento de 3,27% quando comparado com o trimestre anterior do mesmo ano. 
    </Typography>
    <Typography align="justify" paddingTop="10px" variant="body1" style={{ fontSize: "18px" }}>
      Exemplares da Produção Maranhense 
    </Typography>
      <ul>
        <li>
          <Typography align="justify" paddingTop="2px" variant="body1" style={{ fontSize: "18px" }}>
           Doce de Leite - Doce Pioneiro
          </Typography>
        </li>
        <li>
          <Typography align="justify" paddingTop="2px" variant="body1" style={{ fontSize: "18px" }}>
            Queijo - Queijo do Sertão, Latícinio Beth
          </Typography>
        </li>
        <li>
          <Typography align="justify" paddingTop="2px" variant="body1" style={{ fontSize: "18px" }}>
            Queijo de Cabra - Queijo Eldorado
          </Typography>
        </li>     
      </ul>
    </div>
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", textAlign: "center", marginRight: "20px" }}>
    <img
        alt="boi"
        height="200hr"
        src={`../../assets/nelore.webp`}
        style={{ cursor: "pointer"}}
      />
      <Typography>
        Nelore - Reprodução: Google Images
      </Typography>
      <img
        alt="boi"
        height="200hr"
        src={`../../assets/guzera.jpg`}
        style={{ cursor: "pointer"}}
      />
      <Typography>
        Guzera - Reprodução: Google Images
      </Typography>
      </div>


    <Typography>



    </Typography>
  


    </Box>
  );
};

export default Bovinocultura;
