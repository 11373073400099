import { Box, useTheme } from "@mui/material";
import GeographyChart from "../../components/GeographyChart";
import Header from "../../components/Header";
import { tokens } from "../../theme";

const Geography = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Box m="20px">
      <Header title="Dados espaciais" subtitle="Dados espaciais (em construção)" />

      <Box
        height="75vh"
        border={`1px solid ${colors.grey[100]}`}
        borderRadius="4px"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
         <img
            alt="texto"
            height="700hr"
            src={`../../assets/mapasite.png`}
            style={{ cursor: "pointer" }}
          />
      </Box>
    </Box>
  );
};

export default Geography;