import React, { useState } from "react";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import { tokens } from "../../theme";
import TimelineOutlinedIcon from "@mui/icons-material/TimelineOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCartCheckoutOutlined';
import { AddBusinessSharp, CropOriginalOutlined } from "@mui/icons-material";
import HomeIcon from '@mui/icons-material/Home';
import LinkIcon from '@mui/icons-material/Link';
import CloudIcon from '@mui/icons-material/Cloud';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import AgricultureIcon from '@mui/icons-material/Agriculture';
import FilterVintageIcon from '@mui/icons-material/FilterVintage';
import ForestIcon from '@mui/icons-material/Forest';
import PhishingIcon from '@mui/icons-material/Phishing';
import Icon from '@mdi/react';
import { mdiBee, mdiBeehiveOutline, mdiEgg, mdiSilo, mdiCow, mdiPigVariantOutline, mdiSheep, mdiHorseVariant } from '@mdi/js';

const MenuItem = ({ title, to, icon, subItems, history }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);

  const toggleSubMenu = () => {
    setIsSubMenuOpen(!isSubMenuOpen);
  };
  
  const renderLink = () => {
    if (to) {
      return (
        <Link to={to} style={{ textDecoration:'none', color: 'inherit'}}>
          {title}
        </Link>
      );
    } else {
      return (
        <span onClick={toggleSubMenu}>
          {title}
        </span>
      );
    }
  };

  return (
    <div>
      <div
        onClick={subItems ? toggleSubMenu : null}
        style={{
          display: 'flex',
          alignItems: 'center',
          padding: '10px',
          color: colors.grey[100],
          cursor: subItems ? 'pointer' : 'default',
        }}
      >
        {icon}
        <Typography>
          {renderLink()}
        </Typography>
      </div>
      {isSubMenuOpen && subItems && (
        <div style={{ paddingLeft: '20px' }}>
          {subItems.map((item, index) => (
            <MenuItem
              key={index}
              title={item.title}
              to={item.to}
              icon={item.icon}
              subItems={item.subItems || []}
            />
          ))}
        </div>
      )}
    </div>
  );
};


const Sidebar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [isCollapsed, setIsCollapsed] = useState(false); // Definindo isCollapsed e setIsCollapsed aqui

  return (
    <Box
      sx={{
        "& .pro-sidebar-inner": {
          background: `${colors.blueAccent[800]} !important`,
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-inner-item": {
          padding: "5px 35px 5px 20px !important",
        },
        "& .pro-inner-item:hover": {
          color: "#868dfb !important",
        },
        "& .pro-menu-item.active": {
          color: "#6870fa !important",
        },
      }}
    >
      <div>
        {/* LOGO AND MENU ICON */}
        <div
          onClick={() => setIsCollapsed(!isCollapsed)}
          style={{
            margin: "10px 0 20px 0",
            color: colors.grey[100],
            cursor: 'pointer',
          }}
        >
          {!isCollapsed && (
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              ml="15px"
            >
              <img
                alt="profile-user"
                width="125px"
                height="50px"
                src={`../../assets/gov__ma.png`}
              />
              <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                <MenuOutlinedIcon />
              </IconButton>
            </Box>
          )}
        </div>

        {/* Main Menu */}
        <MenuItem
          title="Pagina Inicial"
          to="/"
          icon={<HomeIcon />}
        />
        <MenuItem
          title="Principais Mercados"
          to="/invoices"
          icon={<ShoppingCartIcon />}
        />
        <MenuItem
          title="Maranhão Produtor"
          to="/maranhaoprodutor"
          icon={<AddBusinessSharp />}
        />
        <MenuItem
          title="Maranhão de Oportunidades"
          to="/maranhaooportunidades"
          icon={<TimelineOutlinedIcon />}
        />
        <MenuItem
          title="AgroMetereologia"
          to="/agrometeorologia"
          icon={<CloudIcon />}
        />
        <MenuItem
          title="Pecuaria das águas"
          to="/pecuariaagua"
          icon={<PhishingIcon />}
        />
        <MenuItem
          title="Pecuária"
          to="/"
          icon={<HistoryEduIcon />}
          subItems={[
            {
              title: "Apicultura",
              to: "/apicultura",
              icon: <Icon path={mdiBee} size={1}  />,
            },
            {
              title: "Avicultura",
              to: "/avicultura",
              icon: <Icon path={mdiEgg} size={1} />,
            },
            {
              title: "Bovinocultura",
              to: "/bovinocultura",
              icon: <Icon path={mdiCow} size={1}/>,
            },
            {
              title: "Bubalinocultura",
              to: "/bubalinocultura",
              icon: <ShoppingCartIcon />,
            },
            {
              title: "Equinocultura",
              to: "/equinocultura",
              icon: <Icon path={mdiHorseVariant} size={1} />,
            },
            {
              title: "Ovinocultura e Caprinocultura",
              to: "/ovicaprinocultura",
              icon: <Icon path={mdiSheep} size={1} />,
            },
            {
              title: "Suinocultura",
              to: "/suinocultura",
              icon: <Icon path={mdiPigVariantOutline} size={1}  />,
            },
          ]}
        />
        <MenuItem
          title="Agricultura"
          to="/"
          icon={<AgricultureIcon />}
          subItems={[
            {
              title: "Fruticultura",
              to: "/fruticultura",
              icon: <FilterVintageIcon />,
            },
            {
              title: "Silvicultura",
              to: "/silvicultura",
              icon: <ForestIcon />,
            },
            {
              title: "Grãos e Cereais",
              to: "/graoecereais",
              icon: <Icon path={mdiSilo} size={1} />,
            },
          ]}
        />
        <MenuItem
          title="AGERP"
          to="onClick={window.location.href = 'www.google.com'}"
          icon={<LinkIcon />}
        />
        <MenuItem
          title="ITERMA"
          to="/ITERMA"
          icon={<LinkIcon />}
        />
        <MenuItem
          title="IMESC"
          to="/line"
          icon={<LinkIcon />}
        />
        <MenuItem
          title="Conhecendo o Maranhão"
          to="/geography"
          icon={<MapOutlinedIcon />}
        />
        
      </div>
    </Box>
  );
};

export default Sidebar;
