import { Box, Typography } from "@mui/material";
import Header from "../../components/Header";

const AgroMetereologia = () => {
  return (
    <Box m="80px">
      
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        <img
          alt="AgroMeteorologia"
          height="200hr"
          src={`../../assets/agro.png`}
          style={{ cursor: "pointer" }}
          paddingTop="5px"
        />
      </div>
    
    <div style={{ textAlign:"justify", textIndent:"40px"}}>
    <Typography align="justify" paddingTop="2px" variant="body1" style={{ fontSize:"18px", marginTop: "5px" }}>
      A agricultura é um dos setores econômicos que mais recebe influência direta dos elementos meteorológicos. Em todas as fases e tipos de cultivos identifica-se a intervenção climática, desde o período da semeadura, perpassando o estágio da maturação, até o momento da colheita. Devido a isso, considera-se de extrema importância utilizar as informações meteorológicas, uma vez que estas servem como ferramenta fundamental para o agricultor elaborar um bom planejamento agrícola, a fim de obter o maior aproveitamento da safra.
    </Typography>
    <Typography align="justify" paddingTop="2px" variant="body1" style={{ fontSize:"18px", marginTop: "5px" }}>
      As previsões meteorológicas visam reduzir os riscos e impactos de eventuais fenômenos climáticos que podem afetar o setor agrícola, tendo por finalidade fortalecer e promover uma produtividade elevada no campo, e consequentemente, prevenindo também dos riscos econômicos, haja vista que qualquer adversidade climática que afetar o processo produtivo, incidirá diretamente no custo final do produto cultivado, consequentemente, elevando o seu preço de comercialização.
    </Typography>
    <Typography align="justify" paddingTop="2px" variant="body1" style={{ fontSize:"18px", marginTop: "5px" }}>
      Dessa forma, afirma-se que voltar atenção às boas condições de tempo para o processo produtivo constitui como sinal de prudência agrícola, uma vez que efeitos negativos dos fenômenos climáticos extremos podem ser sentidos tanto pelo produtor quanto pelo consumidor final.
    </Typography>
    <Typography align="justify" paddingTop="2px" variant="body1" style={{ fontSize:"18px", marginTop: "5px" }}>
      Alguns produtos como zoneamento de risco climático, previsão do tempo e prognósticos meteorológicos são de fundamental importância para servir como ferramenta de auxílio no planejamento agrícola, conduzindo o produtor a melhores tomadas de decisão no processo do plantio à colheita, a fim de obter o aproveitamento máximo da safra.
    </Typography>
    <Typography align="justify" paddingTop="2px" variant="body1" style={{ fontSize:"18px", marginTop: "5px" }}>
      Nesse sentido, o produtor é o principal beneficiado ao tomar ciência das informações meteorológicas para elaboração do planejamento agrícola, pois ele arcará com custos elevadíssimos em caso de perdas na safra. Desse modo, a agrometeorologia pode contribuir para que haja um bom gerenciamento da área para a produção agrícola, realizando previsões meteorológicas por meio de modelos matemáticos e estatísticos, visando o maior índice de assertividade das previsões, para que o produtor possa se preparar para enfrentar os possíveis fenômenos climáticos extremos.
    </Typography>
    <Typography align="justify" paddingTop="2px" variant="body1" style={{ fontSize:"18px", marginTop: "5px" }}>
      Dessa forma, afirma-se que é de suma importância levar em consideração as informações meteorológicas para que o agricultor possa gerenciar da melhor maneira possível a área de plantio, uma vez que este encontra-se sob os efeitos das variáveis meteorológicas. Nas (Figuras 1 a 5) apresenta-se as principais variáveis meteorológicas que influenciam o desenvolvimento agrícola.
    </Typography>
    </div>

    <Typography>



    </Typography>
  

    
    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", marginTop: "10px"  }}>
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", textAlign: "center", marginRight: "20px" }}>
          <img
            alt="texto"
            height="700hr"
            src={`../../assets/fig1_agro.png`}
            style={{ cursor: "pointer" }}
            paddingTop="30px"
          />
          <Typography variant="h6" style={{ marginTop: "10px"}}>
            Figura 1. Índices de Precipitação no Estado do Maranhão, conforme a normal climatológica do período de 1191 - 2020.
          </Typography>
        </div>

        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", textAlign: "center" }}>
          <img
            alt="texto"
            height="700hr"
            src={`../../assets/fig2_agro.png`}
            style={{ cursor: "pointer" }}
            paddingTop="30px"
          />
          <Typography variant="h6" style={{ marginTop: "10px"}}>
            Figura 2. Índices de Evaporação no Estado do Maranhão, conforme a normal climatológica do período de 1191 - 2020.
          </Typography>
        </div>
      </div>

      <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", marginTop: "10px"  }}>
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", textAlign: "center", marginRight: "20px" }}>
          <img
            alt="texto"
            height="700hr"
            src={`../../assets/fig3_agro.png`}
            style={{ cursor: "pointer" }}
            paddingTop="30px"
          />
          <Typography variant="h6" style={{ marginTop: "10px"}}>
            Figura 3. Índices de Evotranspiração no Estado do Maranhão, conforme a normal climatológica do período de 1191 - 2020.
          </Typography>
        </div>

        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", textAlign: "center" }}>
          <img
            alt="texto"
            height="700hr"
            src={`../../assets/fig4_agro.png`}
            style={{ cursor: "pointer" }}
            paddingTop="30px"
          />
          <Typography variant="h6" style={{ marginTop: "10px"}}>
            Figura 4. Índices de Temperatura Máxima no Estado do Maranhão, conforme a normal climatológica do período de 1191 - 2020.
          </Typography>
        </div>
      </div>

      <div style={{ display: "flex", flexDirection: "column", alignItems: "center", textAlign: "center", marginTop: "10px"  }}>
        <img
          alt="texto"
          height="700hr"
          src={`../../assets/fig5_agro.png`}
          style={{ cursor: "pointer" }}
          paddingTop="30px"
        />
      </div>
      <div style={{ textAlign: "center", margin: "20px" }}>
      <Typography variant="h6" style={{ marginTop: "10px"}}>
          Figura 5. Índices de Umidade Relativa no Ar no Estado do Maranhão, conforme a normal climatológica do período de 1191 - 2020.
      </Typography>
      </div>
     
    </Box>
  );
};

export default AgroMetereologia;
