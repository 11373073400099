import { Box, Typography } from "@mui/material";

const Graosecereais = () => {
  return (
    <Box m="80px">
      
     
    
    <div style={{ textAlign:"justify", textIndent:"40px"}}>
    
    <Typography align="center" paddingTop="2px" variant="body1" style={{ fontSize:"18px", marginTop: "5px" }}>
      SOJA
    </Typography>      
    <Typography align="justify" paddingTop="2px" variant="body1" style={{ fontSize:"18px", marginTop: "5px" }}>
    A soja (Glycine max L.) é uma das principais culturas de grãos do Brasil.A concentração da produção de soja ocorre na região sul maranhense, com destaque para o município de Tasso Fragoso, seguido de Balsas. a produção de grão coloca os dois municípios entre as cidades mais ricas do agronegócio no Brasil, de acordo com ranking divulgado pelo Ministério da Agricultura, Pecuária e Abastecimento (Mapa). 
    </Typography>
    <Typography align="justify" paddingTop="2px" variant="body1" style={{ fontSize:"18px", marginTop: "5px" }}>
    A estimativa para o fechamento de 2023 é de 3.910.000 toneladas no estado. 
    </Typography>
    
    <Typography align="center" paddingTop="2px" variant="body1" style={{ fontSize:"18px", marginTop: "5px" }}>
      MILHO EM GRÃO
    </Typography>
    <Typography align="justify" paddingTop="2px" variant="body1" style={{ fontSize:"18px", marginTop: "5px" }}>
    A quantidade de milho (Zea mays L.)produzida na safra 2022/2023 foi de 88.461.943 milhões de toneladas. O principal destino da safra são as indústrias de rações para animais, e o restante é destinado ao consumo dos brasileiros e outras produções.
    </Typography>
    <Typography align="justify" paddingTop="2px" variant="body1" style={{ fontSize:"18px", marginTop: "5px" }}>
    Apesar de ser produzido em todo o estado, a região sul do estado foi detentora da maior produção do grão. O destaque ficou por conta dos municípios de Balsas, Tasso Fragoso e São Raimundo das Mangabeiras.
    </Typography>

    <Typography align="center" paddingTop="2px" variant="body1" style={{ fontSize:"18px", marginTop: "5px" }}>
     ARROZ
    </Typography>
    <Typography align="justify" paddingTop="2px" variant="body1" style={{ fontSize:"18px", marginTop: "5px" }}>
    O arroz (Oryza sativa) é um dos principais cereais da base alimentar, sendo um dos mais produzidos e consumidos no mundo. O Maranhão é o maior produtor de arroz do nordeste  e tem destaque nacional na produtividade. 
    </Typography>
    <Typography align="justify" paddingTop="2px" variant="body1" style={{ fontSize:"18px", marginTop: "5px" }}>
    Com cultivo presente em praticamente todo o território maranhense, os municípios que mais produziram arroz foram São Mateus do Maranhão, Grajaú e Arari. 
    </Typography>
    <Typography align="justify" paddingTop="2px" variant="body1" style={{ fontSize:"18px", marginTop: "5px" }}>
    Na safra de 2022 foram 99.868 hectares plantados, com produtividade média  de 1.814 Kg por hectare.
    </Typography>

    <Typography align="center" paddingTop="2px" variant="body1" style={{ fontSize:"18px", marginTop: "5px" }}>
     AALGODÃO
    </Typography>
    <Typography align="justify" paddingTop="2px" variant="body1" style={{ fontSize:"18px", marginTop: "5px" }}>
    As principais variedades de algodão plantadas comercialmente no Brasil são de porte herbáceo (Gossypium hirsutum L. r. latifolium Hutch) e o arbóreo (Gossypium hirsutum L. r. marie galante). 
    </Typography>
    <Typography align="justify" paddingTop="2px" variant="body1" style={{ fontSize:"18px", marginTop: "5px" }}>
    Os plantios de algodão arbóreo concentram-se, principalmente, na região Nordeste do Brasil. Já no Maranhão sua produção está concentrada na região sul do estado, tendo os municípios Tasso Fragoso e Balsas com altos índices de produtividade, gerando em 2021 R$ 307,6 milhões de receita. 
    </Typography>




   
    <Typography>



    </Typography>
  

    
    
    </div> 
    </Box>
  );
};

export default Graosecereais;
