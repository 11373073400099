import * as React from "react";
import { Box, Typography } from "@mui/material";
import { LineChart } from "@mui/x-charts/LineChart";
import { BarChart } from "@mui/x-charts/BarChart";

const Maranhaooportunidades = () => {
  const databov = {
    xAxis: [
      {
        id: "Years",
        data: [
          new Date(2017, 0, 1),
          new Date(2018, 0, 1),
          new Date(2019, 0, 1),
          new Date(2020, 0, 1),
          new Date(2021, 0, 1),
          new Date(2022, 0, 1),
        ],
        scaleType: "time",
        valueFormatter: (date) => date.getFullYear().toString(),
      },
    ],
    series: [
      {
        data: [7687695, 7793180, 8008643, 8323445, 8561509, 9428128],
        name: "Produção",
      },
    ],
  };

  const datagali = {
    xAxis: [
      {
        id: "Years",
        data: [
          new Date(2018, 0, 1),
          new Date(2019, 0, 1),
          new Date(2020, 0, 1),
          new Date(2021, 0, 1),
          new Date(2022, 0, 1),
        ],
        scaleType: "time",
        valueFormatter: (date) => date.getFullYear().toString(),
      },
    ],
    series: [
      {
        data: [ 121334673, 11847738, 13161960, 13130918, 12464424],
        name: "Rebanho",
      },
    ],
  };

  const datagalin = {
    xAxis: [
      {
        id: "Years",
        data: [
          new Date(2017, 0, 1),
          new Date(2018, 0, 1),
          new Date(2019, 0, 1),
          new Date(2020, 0, 1),
          new Date(2021, 0, 1),
          new Date(2022, 0, 1),
        ],
        scaleType: "time",
        valueFormatter: (date) => date.getFullYear().toString(),
      },
    ],
    series: [
      {
        data: [ 2678509, 2673273, 2623185, 2658064, 2660871, 3081665 ],
        name: "Rebanho",
      },
    ],
  };

  return (
    <Box m="80px">
      <div style={{ textAlign: "justify", textIndent: "40px" }}>
        <Typography variant="h2" paddingTop="5px">
          Bovino
        </Typography>
        <Typography align="justify" paddingTop="2px" variant="body1" style={{ fontSize: "18px", marginTop: "5px" }}>
          A bovinocultura maranhense no ano de 2022 apresentou um aumento de 10,12% quando comparado com o ano anterior. E um aumento de 20,97% nos últimos cinco anos.
        </Typography>
      </div>

      <div style={{ display: "flex", flexDirection: "column", alignItems: "center", marginTop: "20px" }}>
        <Typography variant="h6" style={{ marginBottom: "10px" }}>
          Evolução do Rebanho efetivo nos últimos 5 anos
        </Typography>
        <LineChart
          xAxis={databov.xAxis}
          series={databov.series}
          width={500}
          height={300}
          margin={{ left: 80, top: 10, right: 20 }}
        />
      </div>

      <div style={{ textAlign: "justify", textIndent: "40px" }}>
        <Typography align="justify" paddingTop="2px" variant="body1" style={{ fontSize: "18px", marginTop: "5px" }}>
          O quantitativo de bovinos abatidos no Estado no ano de 2022 apresentou um aumento de 10,73% quando comparado com o ano anterior.
        </Typography>
      </div>

      <div style={{ display: "flex", flexDirection: "column", alignItems: "center", marginTop: "20px" }}>
        <Typography variant="h6" style={{ marginBottom: "10px" }}>
          Quantitativo de bovinos abatidos nos últimos 3 anos
        </Typography>
        <LineChart
          xAxis={[{ scaleType: 'band', data: [ '2020', '2021', '2022' ]}]}
          series={[{ data: [ 573081, 567263, 628180 ]}]}
          width={500}
          height={300}
          margin={{ left: 80, top: 10, right: 20 }}
        />
      </div>

      <div style={{ textAlign: "justify", textIndent: "40px" }}>
        <Typography variant="h2" paddingTop="5px">
          Avicultura
        </Typography>
        <Typography align="justify" paddingTop="2px" variant="body1" style={{ fontSize: "18px", marginTop: "5px" }}>
          A avicultura maranhense no ano de 2022 apresentou uma queda  de 5,08’% quando comparado com o ano anterior. E um crescimento de 2,71% nos últimos cinco anos.
        </Typography>
      </div>

      <div style={{ display: "flex", flexDirection: "column", alignItems: "center", marginTop: "20px" }}>
        <Typography variant="h6" style={{ marginBottom: "10px" }}>
          Evolução do rebanho efetivo de galináceos nos últimos 5 anos
        </Typography>
        <LineChart
          xAxis={datagali.xAxis}
          series={datagali.series}
          width={500}
          height={300}
          margin={{ left: 80, top: 10, right: 20 }}
        />
      </div>

      <div style={{ textAlign: "justify", textIndent: "40px" }}>
        <Typography align="justify" paddingTop="2px" variant="body1" style={{ fontSize: "18px", marginTop: "5px" }}>
          O quantitativo do rebanho de galinhas no ano de 2022 apresentou um aumento de 15,81% quando comparado com o ano anterior. E um crescimento de 15,05% nos últimos cinco anos.
        </Typography>
      </div>
      <div style={{ display: "flex", flexDirection: "column", alignItems: "center", marginTop: "20px" }}>
        <Typography variant="h6" style={{ marginBottom: "10px" }}>
          Evolução do rebanho efetivo de galinhas nos últimos 5 anos
        </Typography>
        <LineChart
          xAxis={datagalin.xAxis}
          series={datagalin.series}
          width={500}
          height={300}
          margin={{ left: 80, top: 10, right: 20 }}
        />
      </div>
      <div style={{ textAlign: "justify", textIndent: "40px" }}>
        <Typography align="justify" paddingTop="2px" variant="body1" style={{ fontSize: "18px", marginTop: "5px" }}>
          O quantitativo de frangos abatidos no Estado no ano de 2022 apresentou uma queda de 3,27% quando comparado com o ano anterior. 
        </Typography>
      </div>

      <div style={{ display: "flex", flexDirection: "column", alignItems: "center", marginTop: "20px" }}>
        <Typography variant="h6" style={{ marginBottom: "10px" }}>
          Quantitativo de frangos abatidos nos últimos 3 anos
        </Typography>
        <LineChart
          xAxis={[{ scaleType: 'band', data: [ '2020', '2021', '2022' ]}]}
          series={[{ data: [ 1038414, 989825, 957522 ]}]}
          width={500}
          height={300}
          margin={{ left: 80, top: 10, right: 20 }}
        />
      </div>


      <div style={{ textAlign: "justify", textIndent: "40px" }}>
        <Typography variant="h2" paddingTop="5px">
          Soja
        </Typography>
        <Typography align="justify" paddingTop="2px" variant="body1" style={{ fontSize: "18px", marginTop: "5px" }}>
          A produção de soja no Maranhão apresentou um crescimento de 5,5% em relação ao ano anterior. De acordo com o 5° Levantamento da Safra de Grãos 2022/2023, divulgado pela Companhia Nacional de Abastecimento (Conab), esse crescimento se dá pelo aumento da área plantada, e bom rendimento dos grãos, gerando uma produtividade média de 3.392 kg/ha. 
        </Typography>
      </div>

      <div style={{ display: "flex", flexDirection: "column", alignItems: "center", marginTop: "20px" }}>
        <Typography variant="h6" style={{ marginBottom: "10px" }}>
         Evolução da produção de soja nos últimos 3 anos
        </Typography>
        <LineChart
          xAxis={[{ scaleType: 'band', data: [ '2020', '2021', '2022' ]}]}
          series={[{ data: [ 3285600, 3573600, 3910000 ]}]}
          width={500}
          height={300}
          margin={{ left: 80, top: 10, right: 20 }}
        />
      </div>

      <div style={{ textAlign: "justify", textIndent: "40px" }}>
        <Typography variant="h2" paddingTop="5px">
          Milho
        </Typography>
        <Typography align="justify" paddingTop="2px" variant="body1" style={{ fontSize: "18px", marginTop: "5px" }}>
          A produção de milho no estado apresentou no ano de 2023 crescimento de 11,2% em relação ao ano anterior. Sendo o segundo grão mais plantado no Maranhão, este rotaciona bem com a soja, apresentando-se  uma ótima oportunidade para investimentos no ramo de rações.
        </Typography>
      </div>

      <div style={{ display: "flex", flexDirection: "column", alignItems: "center", marginTop: "20px" }}>
        <Typography variant="h6" style={{ marginBottom: "10px" }}>
          Evolução da Produção de milho nos últimos 3 anos
        </Typography>
        <BarChart
          xAxis={[{ scaleType: 'band', data: [ '2020', '2021', '2022' ]}]}
          series={[{ data: [ 1328400, 1539700, 1562600 ] }, {data: [ 1075900, 1366700, 1581200 ] }]}
          width={500}
          height={300}
          margin={{ left: 80, top: 10, right: 20 }}
        />
      </div>

      <div style={{ textAlign: "justify", textIndent: "40px" }}>
        <Typography variant="h2" paddingTop="5px">
          Cana
        </Typography>
        <Typography align="justify" paddingTop="2px" variant="body1" style={{ fontSize: "18px", marginTop: "5px" }}>
          A cana apresentou no estado uma variação de 6,37%, um grande avanço para a cultura no Maranhão.
        </Typography>
      </div>

      <div style={{ display: "flex", flexDirection: "column", alignItems: "center", marginTop: "20px" }}>
        <Typography variant="h6" style={{ marginBottom: "10px" }}>
          Evolução da produção de cana-de-açúcar nos últimos 3 anos
        </Typography>
        <LineChart
          xAxis={[{ scaleType: 'band', data: [ '2020', '2021', '2022' ]}]}
          series={[{ data: [ 2266900, 2158100, 2295200 ] }]}
          width={500}
          height={300}
          margin={{ left: 80, top: 10, right: 20 }}
        />
      </div>

      <div style={{ textAlign: "justify", textIndent: "40px" }}>
        <Typography variant="h2" paddingTop="5px">
          Banana
        </Typography>
        <Typography align="justify" paddingTop="2px" variant="body1" style={{ fontSize: "18px", marginTop: "5px" }}>
          No Maranhão, a cultura da banana apresenta-se como a principal frutífera produzida. Esta apresentou crescimento de 13,8% de 2021 para 2022, mas houve diminuição da produção  na produção de 25,7% até agosto de 2023.
        </Typography>
      </div>

      <div style={{ display: "flex", flexDirection: "column", alignItems: "center", marginTop: "20px" }}>
        <Typography variant="h6" style={{ marginBottom: "10px" }}>
          Evolução da produção de banana nos últimos 3 anos
        </Typography>
        <LineChart
          xAxis={[{ scaleType: 'band', data: [ '2020', '2021', '2022' ]}]}
          series={[{ data: [ 81428.9, 92693.6, 68890.4] }]}
          width={500}
          height={300}
          margin={{ left: 80, top: 10, right: 20 }}
        />
      </div>


    </Box>
  );
};

export default Maranhaooportunidades;