import React from "react";
import { useState } from "react";
import { Routes, Route } from "react-router-dom";
import Topbar from "./scenes/global/Topbar";
import Sidebar from "./scenes/global/Sidebar";
import Dashboard from "./scenes/dashboard";
import Team from "./scenes/team";
import Invoices from "./scenes/invoices";
import Contacts from "./scenes/contacts";
import Bar from "./scenes/bar";
import Form from "./scenes/form";
import Line from "./scenes/line";
import Pie from "./scenes/pie";
import FAQ from "./scenes/faq";
import Geography from "./scenes/geography";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import Agrometeorologia from "./scenes/agrometeorologia";
import Pecuariadasaguas from "./scenes/pecuariaagua";
import Maranhaoprodutor from "./scenes/maranhaoprodutor";
import Maranhaooportunidades from "./scenes/maranhaooportunidades";
import Silvicultura from "./scenes/agricultura/silvicultura";
import Fruticultura from "./scenes/agricultura/fruticultura";
import Graosecereais from "./scenes/agricultura/graosecereais";
import Apicultura from "./scenes/pecuaria/apicultura";
import Avicultura from "./scenes/pecuaria/avicultura";
import Bovinocultura from "./scenes/pecuaria/bovinocultura";
import Bubalinocultura from "./scenes/pecuaria/bubalinocultura";
import Equinocultura from "./scenes/pecuaria/equinocultura";
import Ovinocaprinocultura from "./scenes/pecuaria/ovicapricultura"
import Suinocultura from "./scenes/pecuaria/suinocultura";
import Frutas from "./scenes/frutas";
import Maps from "./scenes/maps";
import Orgaos from "./scenes/orgaos";

function App() {
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);
  
  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
          <Sidebar isSidebar={isSidebar} />
          <main className="content">
            <Topbar setIsSidebar={setIsSidebar} />
            <Routes>
              <Route path="/" element={<Dashboard />} />
              <Route path="/team" element={<Team />} />
              <Route path="/contacts" element={<Contacts />} />
              <Route path="/invoices" element={<Invoices />} />
              <Route path="/form" element={<Form />} />
              <Route path="/bar" element={<Bar />} />
              <Route path="/pie" element={<Pie />} />
              <Route path="/line" element={<Line />} />
              <Route path="/faq" element={<FAQ />} />
              <Route path="/geography" element={<Geography />} />         
              <Route path="/agrometeorologia" element={<Agrometeorologia />} />
              <Route path="/aged" element={<Bar />} />
              <Route path="/pecuariaagua" element={<Pecuariadasaguas />} />
              <Route path="/maranhaoprodutor" element={<Maranhaoprodutor />} />
              <Route path="/maranhaooportunidades" element={<Maranhaooportunidades />} />
              <Route path="/fruticultura" element={<Fruticultura />} />
              <Route path="/silvicultura" element={<Silvicultura />} />
              <Route path="/graosecereais" element={<Graosecereais />} />
              <Route path="/apicultura" element={<Apicultura/>} />
              <Route path="/avicultura" element={<Avicultura/>} />
              <Route path="/bovinocultura" element={<Bovinocultura/>} />
              <Route path="/bubalinocultura" element={<Bubalinocultura/>} />
              <Route path="/equinocultura" element={<Equinocultura/>} />
              <Route path="/ovicapricultura" element={<Ovinocaprinocultura/>} />
              <Route path="/suinocultura" element={<Suinocultura/>} />
              <Route path="/frutas" element={<Frutas />} />
              <Route path="/maps" element={<Maps />} />
              <Route path="/orgaos" element={<Orgaos />} />
              <Route path='/AGERP' component={() => {
                  window.location.href = 'https://agerp.ma.gov.br';
                  return null;
              }}/>
              <Route path='/ITERMA' component={() => {
                  window.location.href = 'https://iterma.ma.gov.br';
                  return null;
              }}/>
            </Routes>
          </main>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
